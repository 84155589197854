import * as React from "react";
import { Link } from "gatsby";

// import Ch from "../../components/Chord.js";
import Chorus from "../../components/Chorus.js";
import Columns from "../../components/Columns.js";
import { Helmet } from "react-helmet";
import Layout from "../../components/Layout";
import Verse from "../../components/Verse.js";

export default function FireAndRain() {
  return (
    <Layout>
      <Helmet title="Fire & Rain | James Taylor" />

      <h1>Fire & Rain</h1>

      <h2>James Taylor</h2>

      <h4>Key: F</h4>

      <h4>Tempo: 124</h4>

      <Link to="/">List</Link>

      <Columns columns="3">
        <Verse>
          <p>Just yesterday mornin', they let me know you were gone</p>
          <p>Suzanne, the plans they made put an end to you</p>
          <p>I walked out this morning and I wrote down this song</p>
          <p>I just can't remember who to send it to</p>
        </Verse>
        <Chorus>
          <p>I've seen fire and I've seen rain</p>
          <p>I've seen sunny days that I thought would never end</p>
          <p>I've seen lonely times when I could not find a friend</p>
          <p>But I always thought that I'd see you again</p>
        </Chorus>
        <Verse>
          <p>Won't you look down upon me, Jesus?</p>
          <p>You've got to help me make a stand</p>
          <p>You've just got to see me through another day</p>
          <p>My body's aching and my time is at hand</p>
          <p>And I won't make it any other way</p>
        </Verse>
        <Chorus>
          <p>Oh, I've seen fire and I've seen rain</p>
          <p>I've seen sunny days that I thought would never end</p>
          <p>I've seen lonely times when I could not find a friend</p>
          <p>But I always thought that I'd see you again</p>
        </Chorus>
        <Verse>
          <p>Been walking my mind to an easy time</p>
          <p>My back turned towards the sun</p>
          <p>Lord knows, when the cold wind blows</p>
          <p>It'll turn your head around</p>
          <p>Well, there's hours of time on the telephone line</p>
          <p>To talk about things to come</p>
          <p>Sweet dreams and flying machines in pieces on the ground</p>
        </Verse>
        <Chorus>
          <p>Oh, I've seen fire and I've seen rain</p>
          <p>I've seen sunny days that I thought would never end</p>
          <p>I've seen lonely times when I could not find a friend</p>
          <p>But I always thought that I'd see you, baby</p>
          <p>One more time again, now</p>
          <p>Thought I'd see you one more time again</p>
          <p>There's just a few things coming my way this time around, now</p>
          <p>Thought I'd see you, thought I'd see you, fire and rain, now</p>
        </Chorus>
      </Columns>
    </Layout>
  );
}
